@tailwind base;
@tailwind components;
@tailwind utilities;
#root{
    -webkit-tap-highlight-color: transparent;
}
.MuiDataGrid-root .MuiDataGrid-cell{
    /* color: tomato; */
    white-space: normal !important;
    word-wrap: break-word !important;
    outline: none !important; 
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-cell:focus{
    outline: none !important; 
}
.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

/* .MuiPaginationItem-root{
    color: red;
} */
