.personal-data-tab-container{
    background-color: white;
    padding: 0;
}

.personal-data-tab-container > section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.personal-data-content{
    flex-grow: 1;
}

.personal-data-content tbody{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.personal-data-content td:first-child{
    width: clamp(8rem, 12vw, 12rem);
    flex-shrink: 0;
    font-weight: bold;
}

.personal-data-content .indented{
    padding-left: 1rem;
}

.personal-data-content td:nth-child(2){
    word-break: break-word;
    display: flex;
    flex-wrap: wrap;
}

.personal-data-content tr{
    color: #374151;

    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

@media screen and (min-width: 1024px) {
    .personal-data-content td:nth-child(2){
        max-width: 100%;
    }
}